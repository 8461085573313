import {on} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

const selectors = {
    base: '.js-scroll-top',
};

export function init() {
    onFind(selectors.base, function (baseElement) {
        on('click', function() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, baseElement)
    });
}