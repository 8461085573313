"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {find,addClass, removeClass, on} from '@elements/dom-utils';

const selectors = {
    btn: '.js-search-toggle',
    overlay: '.js-search-toggle__overlay',
    close: '.js-search-toggle__close',
    input: '.js-search-toggle__input'
};

export function init () {
    onFind(selectors.btn, function (baseElement) {
        let overlay = find(selectors.overlay);
        let input = find(selectors.input);

        on('click', function () {
            addClass('is-open', overlay);
            addClass('overlay-open', document.getElementsByTagName('body')[0]);
            focusInput(input);
            bodyClickHandler(overlay);
        }, baseElement);

        let close = find(selectors.close);
        if(close) {
            on('click', function (evt) {
                evt.preventDefault();

                removeClass('is-open', overlay);
                removeClass('overlay-open', document.getElementsByTagName('body')[0]);
                bodyClickHandler(overlay);
            }, close);
        }
    });
}

function bodyClickHandler(parent) {
    window.addEventListener("click", function _listener(evt) {
        if (!parent.contains(evt.target)) {
            removeClass('is-open', parent);
            removeClass('overlay-open', document.getElementsByTagName('body')[0]);

            window.removeEventListener("click", _listener, true);
        }
    }, true);
}

function focusInput(input) {
    // iOS needs focusing directly in the click event
    input.focus();

    let timeout = setTimeout(function () {
        /* timeout is needed because some browsers don't allow focusing a element
        if it is not visible */
        input.focus();
        clearTimeout(timeout);
    }, 500);
}
