import {onFind} from "@elements/init-modules-in-scope";

import * as toc from './toc';
toc.init({linkClass: 'visually-hidden-focusable'});

import * as lazyImg from '@elements/lazy-img';
lazyImg.init();

import * as fadeIn from './fade-in';
fadeIn.init();

import * as customCursor from './custom-cursor';
customCursor.init();

import * as smallStickyArea from './sticky-button';
smallStickyArea.init();

import * as productSelectionForm from './product-selection-form';
productSelectionForm.init();

import * as renderTemplate from './render-template';
renderTemplate.init();

import * as scrollTop from './scroll-top';
scrollTop.init();

import * as nav from './nav';
nav.init();

import * as actionChanger from "@elements/action-changer";
actionChanger.init();

import Collapse from 'bootstrap/js/dist/collapse';
onFind('[data-toggle="collapse"]',function (element) {
    new Collapse(element);
});

import * as ajaxModal from './ajax-modal';
ajaxModal.init();

import * as searchToggle from './search-toggle';
searchToggle.init();

import * as contentVisibility from './content-visibility';
contentVisibility.init();

import * as tracking from "@elements/tracking";
let options = {debug: true}; // optional options
tracking.initGtmEventTracking(options); // for GTM tracking

import * as formTracking from './form-tracking';
formTracking.init();

import * as selectTracking from './select-tracking';
selectTracking.init();

import * as friendlyRecaptcha from './friendlyRecaptcha';
friendlyRecaptcha.init();

import * as skipLinks from '@elements/skip-links';
skipLinks.init();
