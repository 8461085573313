"use strict";

import throwError from '@elements/throw-error';
import {onFind} from "@elements/init-modules-in-scope";
import {on, findAll, closest, find, findAllIn} from "@elements/dom-utils";

let id = 0;

const defaultOptions = {
    linkClass: 'sr-only sr-only-focusable',
    offset: 100
};

const defaultSelectors = {
    list: '.js-toc__list',
    title: '.js-toc__title',
};

function isFocusable( el ) {
    let tabIndex = +el.getAttribute( "tabindex");
    tabIndex = isNaN( tabIndex ) ? -1 : tabIndex;
    return (el.tagName === 'INPUT' || (el.tagName === 'A' && el.href) || (el.tagName === 'AREA' && el.href) || el.tagName === 'IFRAME') || tabIndex > -1;
}

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.list, function (baseElement) {
        createToc(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createToc(tocList, options = defaultOptions, selectors = defaultSelectors) {
    options = {...defaultOptions, ...options};
    selectors = {...defaultSelectors, ...selectors};

    if (!tocList) {
        throwError(`can\'t render Table of content. No ${selectors.list} found`);
    }

    onFind(selectors.title, function (title) {
        let anchorId = 'toc-entry-target-' + id;
        title.setAttribute('id', 'toc-entry-target-' + id);
        if (!isFocusable(title)) {
            title.setAttribute('tabindex', '-1');
        }
        id++;
        addTocEntry(title.textContent, anchorId);
    });

    let tocListLinks = findAllIn('button', tocList);

    tocListLinks.map(function(tocListLink) {
        on('click', function (evt) {
            let target = find(tocListLink.getAttribute('data-href'));

            if (!target) {
                throwError('Could not jump to "', tocListLink.getAttribute('data-href'), '". Element was not found');
                return;
            }

            let tabPaneParent = closest('.tab-pane:not(.active)', target);
            if (tabPaneParent) {
                let targetId = '#' + tabPaneParent.getAttribute('id');
                let triggerSelectors = findAll('[data-toggle="tab"]').filter(el => el.dataset.target === targetId || el.getAttribute('href') === targetId);
                if(triggerSelectors) {
                    triggerSelectors.map(trigger => {
                        new BSN.Tab(trigger).show();
                        window.scrollTo(window.scrollX, tabPaneParent.parentElement.getBoundingClientRect().top - options.offset);
                        tabPaneParent.parentElement.focus();
                    })
                }
            }

            let collapseParent = closest('.collapse:not(.show)', target);
            if (collapseParent) {
                let targetId = "#" + collapseParent.getAttribute('id');
                let triggerSelectors = findAll('[data-toggle="collapse"]').filter(el => el.dataset.target === targetId || el.getAttribute('href') === targetId);

                if(triggerSelectors) {
                    triggerSelectors.map(trigger => {
                        new BSN.Collapse(trigger).show();
                        window.scrollTo(window.scrollX, collapseParent.getBoundingClientRect().top - options.offset);
                        collapseParent.focus();
                    })
                }

            }

            if(!collapseParent && !tabPaneParent){
                window.scrollTo(window.scrollX, target.getBoundingClientRect().top - options.offset);
                target.focus();
            }
        }, tocListLink);
    });

    function addTocEntry(text, anchorId) {
        tocList.innerHTML += `<li><button class="${options.linkClass}" data-href="#${anchorId}">${text}</button></li>`;
    }
}
